import { Config } from '../../interfaces/config.interface';
import Logo from './logo.png';

const config: Config = {
  logo: Logo,
  title: 'Actividades de voluntariado',
  logo_width: '250px',
  name: 'Grupo Supervielle',
  has_hours: true,
  map_top: false,
  map_bottom: true,
  exception: true,
  companyTypes: [
    { label: 'Banco Supervielle', value: 'Banco Supervielle' },
    { label: 'Espacio Cordial Servicios', value: 'Espacio Cordial Servicios' },
    { label: 'Supervielle Seguros', value: 'Supervielle Seguros' },
    { label: 'Tarjeta Automática', value: 'Tarjeta Automática' },
    { label: 'Supervielle Asset Management', value: 'Supervielle Asset Management' },
    { label: 'Cordial Compañía Financiera', value: 'Cordial Compañía Financiera' },
    { label: 'Invertir Online', value: 'Invertir Online' },
    { label: 'IUDU', value: 'IUDU' },
  ],
  theme: {
    primary: '#d52b1e',
    secondary: '#424242',
  },
};

export default config;
